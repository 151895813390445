import React from 'react';

/**
 * @version 1.0
 * @summary Issue Date: 1 Novemeber 2024
 */
export default function PrivacyPolicy() {
  return (
    <body style={{ backgroundColor: 'white' }}>
      <header>
        <strong>Ventory Privacy Policy</strong>
      </header>

      <main>
        <br></br>
        <section>
          <strong>1. Introduction</strong>
          <p>
            As an IT company, Ventory provides services of outstanding quality and safety with careful attention to
            fairness and integrity in our business activities to win the trust and satisfaction of our customers.
          </p>
          <p>
            We are engaged in international business activities, as such, we observe and honor the word and spirit of
            all applicable laws and regulations of the countries in which we operate.
          </p>
          <p>
            At Ventory, we highly value privacy in our relationships with customers, suppliers, and our employees. As
            part of our corporate responsibility, we are committed to compliance with Data Protection Laws. This Privacy
            Policy provides a framework of conditions and principles on how we use and process personal data and how we
            ensure an adequate level of data protection.{' '}
          </p>
          <p>
            We believe that ensuring adequate data protection is the foundation of trustworthy business relationships.{' '}
          </p>
          <p>Each employee of Ventory will be responsible for compliance with this Privacy Policy.</p>
          <p>
            If there is any reason to believe that legal obligations or local legislation contradict with the principles
            of this Privacy Policy, Ventory will work closely with the relevant authorities to find a practical solution
            that meets the legislative requirements as well as the purposes of this Privacy Policy.
          </p>
          <p>
            As part of our compliance program, we will continue reviewing and updating our corporate policies, internal
            processes and contractual relationships where required and monitor regulatory guidance to ensure compliance
            with data protection regulations.
          </p>
          <p>
            The latest version of this Privacy Policy can be found on our website www.ventory.com and will be provided
            upon request. For more information, please contact the HR department of Ventory
          </p>
        </section>

        <br></br>
        <section>
          <strong>2. Basic Principles for Using and Processing Personal Data</strong>
          <p>
            This Privacy Policy establishes a framework of rights and duties which are designed to safeguard personal
            data. It aims to balance the legitimate need of Ventory to collect and use personal data for business or
            other purposes with the right of individuals to retain the privacy of their personal details.
          </p>
          <p>
            This Data Protection Policy is based on 8 principles which define how personal data may be lawfully used and
            processed.
          </p>
          <strong>2.1. Fairness and Lawfulness</strong>
          <p>
            We will use and process personal data lawfully, fairly and in a transparent manner only to the extent
            necessary for providing our services or performing our contractual obligations.
          </p>
          <strong>2.2. Legitimate Purpose</strong>
          <p>
            We will only use personal information for one or more specified and legitimate purposes. Personal data will
            not be used or further processed in any manner incompatible with those purposes. When we need to use
            personal information beyond the scope of such purposes, we shall obtain your (additional) consent, unless
            extended use would be permitted by law or regulation;
          </p>
          <strong>2.3. Data Minimization</strong>
          <p>
            Personal data should be adequate, relevant, and limited to what is necessary in relation to the purpose for
            which it was provided. This means that we will not collect personal data in advance or store personal data
            for (potential) future purposes, unless required or permitted by law;
          </p>
          <strong>2.4. Accuracy</strong>
          <p>
            We will keep personal data accurate and up-to-date and shall take all reasonable steps to ensure that
            personal data that is inaccurate will be removed or rectified without delay;
          </p>
          <strong>2.5. Limited Retention</strong>
          <p>
            We will keep personal data no longer than is necessary for the purposes for which the personal data was
            provided. Unless otherwise prescribed by law, personal data that is no longer needed or relevant will be
            purged or deleted. This will apply to both electronic and non-electronic personal data;
          </p>
          <strong>2.6. General Data Protection Regulation (GDPR)</strong>
          <p>
            We will honor and respect your (data subject’s) rights under the EU General Data Protection Regulation
            (GDPR). This means that you will have the right to know which personal information we store, why we need it
            and how we use or process it. Furthermore, you will have the right to access your personal data, ask for
            rectification, removal, or object to the processing of it;
          </p>
          <strong>2.7. Integrity and Confidentiality</strong>
          <p>
            Your personal data is subject to data secrecy. This means that we will take appropriate technical and
            organizational measures against unauthorized or unlawful use or processing of your personal data and against
            accidental loss, destruction, or damage of your personal data.
          </p>
          <strong>2.8. Data Transfer</strong>
          <p>
            We will not transfer your personal data to a country or a territory outside the European Economic Area,
            unless that country or territory ensures an adequate level of data protection in relation to the processing
            of your personal data;
          </p>
        </section>

        <br></br>
        <section>
          <strong>3. Processing of Customer and Third-Party Data</strong>
          <p>
            Collecting, using, and processing personal data of customers, suppliers and/or other third-parties will only
            be permitted under the following conditions:
          </p>
          <strong>3.1. Data Processing for a Contractual Relationship</strong>
          <p>
            We will only use and process personal data of relevant prospects, customers, suppliers, and/or other
            third-parties in order to establish, execute or terminate a contract. Prior to entering into a contract,
            personal data may be processed to prepare bids, Requests For Quotations (RFQs) or purchase orders and/or to
            fulfill other requests of the customer. We may contact customers in a pre-contractual phase by using the
            information that it has provided. Where appropriate, we will observe any restrictions requested by the
            customer relating to the use and/or processing of personal data.
          </p>
          <strong>3.2. Data Processing for Advertising Purposes</strong>
          <p>
            Personal or customer data may be processed for advertising purposes or market and opinion research, provided
            that this is consistent with the purpose for which the data was originally collected or provided. Where
            appropriate, we will inform our customer about the use of his/her personal data for advertising purposes. If
            the customer objects to the use of their personal data for advertising purposes, we will no longer use the
            data and block it from being used.
          </p>
          <strong>3.3. Consent to Data Processing</strong>
          <p>
            Personal Data will only be processed following consent of the customer or data subject. We will duly inform
            the customer and data subjects about the use and purpose of its personal data before giving consent.
            Although consent may be withdrawn at any time, withdrawal will not affect the lawfulness of processing based
            on consent before its withdrawal.
          </p>
          <strong>3.4. Data Processing pursuant to Legal Authorization</strong>
          <p>
            Customers should be aware that the processing of personal data may (also) be permitted if national
            legislation requires to do so. The type and extent of such data processing should be necessary for the
            lawful and authorized data processing activity, and we will, in such a case, observe all relating and
            relevant statutory provisions.
          </p>
          <strong>3.5. Data Processing pursuant to Legitimate Interest</strong>
          <p>
            are generally of a legal or commercial nature and may include the collection of outstanding receivables or
            to avoid a ‘breach of contract’. However, we will not process personal data for the purposes of a legitimate
            interest if, in any individual case, there is reason to believe or evidence that the interests of a data
            subject merits protection.
          </p>
          <strong>3.6. Processing of Highly Sensitive Data</strong>
          <p>
            We will not process highly sensitive personal data unless the law requires us to do so, or the data subject
            has given explicit consent. We may also process highly sensitive personal data if that would be required for
            asserting, exercising, or defending legal claims regarding or relating to that data subject.
          </p>
          <strong>3.7. User Data and Internet</strong>
          <p>
            We will inform customers and data subjects if we collect, use, or process personal data on websites. The
            information we use will be easy to identify and access and be made available for data subjects upon request.
            If user profiles are created to evaluate and identify the use of websites, the data subjects will be
            properly informed and asked for consent. We will not use personal data for personal tracking, unless
            permitted by law.
          </p>
        </section>

        <br></br>
        <section>
          <strong>4. Processing of Employee Data</strong>
          <p>
            Collecting, processing, and using personal data of Employees will only be permitted under the following
            conditions:
          </p>
          <strong>4.1. Data Processing for the Employment Relationship</strong>
          <p>
            Personal data may be processed in the employment relationship between Ventory and its employees to
            establish, execute or terminate the employment agreement. When establishing an employment relationship, the
            applicants’ personal data may be processed. If the candidate is rejected, we will, where appropriate, purge
            his/her personal data with observance of the statutory retention period, unless the applicant has agreed and
            consented to remain on file for future selection processes (maximum one year).
          </p>
          <p>
            In the existing employment relationship - and to the extent none of the following circumstances for
            authorized data processing would apply - data processing should always relate to the purpose of the
            employment relationship or the execution of the employment agreement. If it would be necessary to collect
            information of an applicant from a third-party (e.g. employment agency) the requirements of the
            corresponding local laws should be observed. In cases of doubt, we will obtain consent from the applicant or
            data subject.
          </p>
          <strong>4.2. Data Processing pursuant to Legal Authorization</strong>
          <p>
            The processing of personal data of employees will also be permitted if national legislation requests,
            requires, or authorizes this. The type and extent of data processing should, in such a case, be necessary
            for the lawful and authorized data processing activity and we will make sure to observe the relevant
            statutory provisions. If there is some legal flexibility, the interests of the employee that merit
            protection will be taken into consideration.
          </p>
          <strong>4.3. Collective Agreements on Data Processing</strong>
          <p>
            If a data processing activity exceeds the purposes of fulfilling an individual employment agreement, it may
            still be lawful on the basis of a collective employment agreement. Collective employment agreements are pay
            scale agreements or agreements between employers and employee representatives within the scope allowed under
            the relevant (national) employment laws. In such a case we will make sure that the agreements will cover the
            specific purpose of the intended data processing activity and will reflect the requirements of (national)
            Data Protection legislation.
          </p>
          <strong>4.4. Consent to Data Processing</strong>
          <p>
            We will duly inform our employees about our personal data activities. Where appropriate, we will ask our
            employees for consent to use and process personal data. Confirmations of consent must be given voluntarily.
            Consent will be obtained in writing or electronically for the purposes of documentation. If consent will be
            given verbally, we will confirm and document it in writing. The Employee may withdraw consent at any time,
            however, such withdrawal will not affect the lawfulness of processing based on consent before its
            withdrawal.
          </p>
          <strong>4.5. Data processing pursuant to Legitimate Interest</strong>
          <p>
            We will be allowed to process personal data if we have a legitimate interest. Legitimate interests are
            generally of a legal or financial nature and may include, amongst others, filing, enforcing, or defending
            against legal claims and restructuring, TUPE or redundancy procedures. We will not process personal data if,
            in any individual case, there is reason to believe or evidence that the interests of the employee merit
            protection. The legitimate interest of the company and any interests of the employee meriting protection
            shall, in such a case, be identified and documented before any measures are taken.
          </p>
          <strong>4.6. Processing of Highly Sensitive Data</strong>
          <ul>
            <li>
              Personal Data is any information that can identify a living individual and may include such items as home
              and work address, personal e-mail address, age, telephone number and even photographs or other images;
            </li>
            <li>
              Highly Sensitive Data consists of racial and ethnic origin, political opinions, religious or philosophical
              beliefs, union membership and the health and sexual life of a Data Subject;
            </li>
          </ul>
          <p>
            The processing of highly sensitive data must be explicitly permitted by the employee or prescribed by
            national law. However, we will be allowed to process highly sensitive data if that would be required by the
            authorities to fulfill its rights and duties in the field of employment law or social security. In all other
            cases, processing of highly sensitive information is subject to prior approval of the HR Director of
            Ventory.
          </p>
          <strong></strong>
          <p></p>
        </section>

        <br></br>
        <section>
          <strong>5. Transfer and External Processing of Personal Data</strong>
          <p>
            Transfer of personal data to recipients inside or outside Ventory is subject to the requirements for
            processing of personal data under articles 2, 3 and 4.
          </p>
          <p>
            We will require the data recipient to (i) only use the personal data for specific and defined purposes; and
            (ii) ensure an adequate level of data protection in relation to the processing of your personal data, either
            by internal policies or by law.
          </p>
          <p>
            If data processing will be carried out on behalf of Ventory, we will require the data recipient to either
            provide a GDPR Compliance Statement or to enter into a Data Processing Agreement.
          </p>
          <p>In such a case, the following recommendations should be observed:</p>
          <ul>
            <li>
              The data recipient should be selected on the basis of its ability to ensure an adequate level of data
              protection;
            </li>
            <li>The instructions and the responsibilities of the data recipient should be duly documented;</li>
            <li>
              Depending on the risks relating to data processing, privacy reviews should be undertaken on a regular
              basis;
            </li>
            <li>
              The use of the EU Standard Contractual Clauses (SCC) for the transfer and processing of personal data will
              be recommended;
            </li>
            <li>
              Accreditation of the data recipient by the European Union for the provision of a sufficient data
              protection level should be considered;
            </li>
          </ul>
        </section>

        <br></br>
        <section>
          <strong>6. Confidentiality and Safeguards</strong>
          <p>
            Personal data will be subject to data secrecy. We will provide our staff access to personal information on a
            ‘need-to-know’ basis only. Access will be provided to the extent appropriate for the execution of their
            functional tasks. Our staff will not be allowed to use personal data for private or commercial purposes, to
            disclose it to unauthorized persons or to make it available in any other way.
          </p>
          <p>
            We will make sure that personal data will be properly safeguarded from unauthorized access and unlawful
            processing or disclosure, as well as accidental loss, modification, or destruction. This principle applies
            regardless of whether data is processed electronically or in paper form.
          </p>
          <p>
            Before the introduction of new methods of data processing, particularly new IT systems, we will define and
            duly implement technical and organizational measures to protect personal data. The technical and
            organizational measures for protecting personal data are included in Ventory’s IT security guidelines. These
            guidelines will be reviewed on a regular basis and will be amended to technical developments and
            organizational changes.
          </p>
        </section>

        <br></br>
        <section>
          <strong>7. Data Protection Assessments</strong>
          <p>
            Ventory will check compliance with the EU General Data Protection Regulation and this Privacy Policy on a
            regular basis with Data Protection Impact Assessments, Internal Audits and other available and appropriate
            controls. The results and effectiveness of these data protection controls will be reported to Ventory. On
            request, the results of these data protection controls will be made available to the responsible Data
            Protection Authorities. We should note that the responsible Data Protection Authorities may perform their
            own controls of compliance with the data protection regulations and this Privacy Policy, as permitted under
            national law.
          </p>
        </section>

        <br></br>
        <section>
          <strong>8. Data Breach and Notification System</strong>
          <p>
            All Employees should inform their supervisor, manager, and HR officer immediately in case of a (potential)
            violation of this Privacy Policy, a personal data breach or any other regulations for the protection of
            personal data. Events considered to constitute to a data breach are, amongst others:
          </p>
          <ul>
            <li>improper transmission of personal data to third-parties;</li>
            <li>improper access by third-parties to personal data;</li>
            <li>loss of personal data.</li>
          </ul>
          <p>
            In case of a personal data breach, Ventory shall without undue delay and, where feasible, not later than 72
            hours after having become aware or notified of such a breach, notify the competent Data Protection
            Authorities. This notification should, amongst others, include:
          </p>
          <ul>
            <li>
              Description and nature of the personal data breach, including the categories and approximate number of
              Data Subjects;
            </li>
            <li>Name and Contact details of the responsible HR officer;</li>
            <li>Description of the likely (potential) consequences of the personal data breach;</li>
            <li>
              Description of the measures taken to address the personal data breach and/or mitigate the consequences.
            </li>
          </ul>
          <p>
            (Potential) Data breaches will be reported with the Data Breach Notification Report Template annexed to this
            Privacy Policy as Annex 1.
          </p>
        </section>

        <br></br>
        <section>
          <strong>9. Responsibilities</strong>
          <p>
            Policy and adhering to applicable (national) data protection regulations. Our management will make sure all
            organizational, HR and technical measures are in place, such that processing of personal data may be carried
            out safely and in accordance with the EU General Data Protection Regulation (or any other relevant data
            protection law) and this Privacy Policy.
          </p>
          <p>
            Ventory will assign a Privacy Officer who will be responsible for (i) implementation of this Privacy Policy;
            (ii) regular data protection (impact) assessments; and (iii) adequately training staff on data protection
            and awareness. Ventory will provide additional support where necessary or required.
          </p>
          <p>
            We encourage any employee or data subject to approach the Privacy Officer (or the HR Department of Ventory)
            at any time to raise concerns, ask questions, request information or make complaints relating to data
            protection or data security issues. We will make sure that concerns and complaints will be handled
            adequately and in a confidential manner.
          </p>
          <p>
            All should be aware that Improper processing of personal data, or other violations of Data Protection Laws,
            may be criminally prosecuted and result in (significant) claims for compensation of damages. Employees
            should know that violations for which individual employees are responsible may lead to sanctions under
            employment law, including termination of employment.
          </p>
        </section>

        <br></br>
        <section>
          <strong>10. Notification Structure Ventory</strong>
          <p>Ventory has implemented the following reporting structure:</p>
          <strong>I. Annexes</strong>
          <ul>
            <li>Annex 1: Data Breach Notification Report (Template);</li>
            <li>Annex 2: Personal Data Request and Notification Form (Template);</li>
            <li>Annex 3: Personal Data Consent Form (Template);</li>
            <li>Annex 4: Ventory GDPR Checklist and Audit Standards</li>
          </ul>
          <br></br>
          <strong>II. Useful Resources</strong>
          <p>The EU General Data Protection Regulation, EU 2016/679:</p>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.ENG'
          >
            http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.ENG
          </a>
          <p>The EC Standard Contractual Clauses:</p>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://ec.europa.eu/info/strategy/justice-and-fundamental-rights/data-protection_en'
          >
            https://ec.europa.eu/info/strategy/justice-and-fundamental-rights/data-protection_en
          </a>
          <br></br>
          <br></br>
          <strong>III. Data Subject Rights and Article Reference</strong>
          <br></br>
          <em>1. Right to Information on Personal Data (art. 13-14 GDPR)</em>
          <p>
            Each Data subject may request information on which personal data relating to him/her has been stored, how
            the data was collected and for what (lawful) purpose;
          </p>
          <p>Ventory</p> <p>HR Director</p> <p>IT Support </p>
          <br></br>
          <em>2. Right to Access to Personal Data (art. 15 GDPR)</em>
          <p>
            If personal data is transmitted to third parties, information shall be provided about the identity of the
            recipient, as well as the purpose of processing;
          </p>
          <br></br>
          <em>3. Right to Rectification (art. 16 GDPR)</em>
          <p>
            If personal data is incorrect or incomplete, the Data Subject may demand that it be corrected or amended;
          </p>
          <br></br>
          <em>4. Right to Erasure (‘Right to be Forgotten’) (art. 17 GDPR)</em>
          <p>
            A Data Subject may request the deletion of his/her personal data if the personal data is no longer necessary
            in relation to the purpose for which it was collected, consent has been withdrawn or if processing of such
            data has no legal basis;
          </p>
          <br></br>
          <em>5. Right to Object to Processing (art. 18-21 GDPR)</em>
          <p>
            A Data Subject generally has the right to object to his/her personal data being processed and this should be
            taken into account if the protection of the Data Subject’s interests takes precedence over the processing
            interest of Ventory. This does not apply if processing of the concerning personal data is based on a legal
            obligation or legitimate grounds;
          </p>
          <br></br>
          <em>6. Right to Data Portability (art. 20 GDPR)</em>
          <p>
            A Data Subject has the right to receive (a copy of) the personal data concerning him/her in a structured,
            commonly used, and machine-readable format and to transmit this data to another data controller, if
            requested.
          </p>
          <br></br>
          <strong>IV. Definitions</strong>
          <br></br>
          <p>
            <em>Consent</em>
          </p>
          <p>
            Any freely given, specific, informed, and unambiguous indication of the Data Subject that he/she agrees with
            the processing of his/her personal data;
          </p>
          <br></br>
          <p>
            <em>Data Breach</em>
          </p>
          <p>
            A breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized
            disclosure of, or access to, personal data transmitted, stored, or otherwise processed;
          </p>
          <br></br>
          <p>
            <em>European Economic Area</em>
          </p>
          <p>The economic region associated with the European Union, including Norway, Iceland, and Liechtenstein;</p>
          <br></br>
          <p>
            <em>Personal Data</em>
          </p>
          <p>
            Personal Data means any information relating to an identified or identifiable natural person (‘Data
            Subject’). An identifiable natural person is one who can be identified, directly or indirectly, by reference
            to an identifier such as a name, identification number, location data, online identifier or to one or more
            factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of
            that natural person;
          </p>
          <br></br>
          <p>
            <em>Highly Sensitive Personal Data</em>
          </p>
          <p>
            Personal data relating to racial and ethnic origin, political opinions, religious or philosophical beliefs,
            union membership, and the health and sexual life of a Data Subject;
          </p>
          <br></br>
          <p>
            <em>Processing</em>
          </p>
          <p>
            Any process, with or without the use of automated systems, to collect, store, organize, retain, modify, use,
            forward, transmit, disseminate, or combine and compare data. This also includes disposing of, deleting and
            blocking data. Where processing is based on Consent, Ventory should be able to demonstrate that the Data
            Subject has consented to the processing of his/her personal data.
          </p>
          <p>***</p>
        </section>
      </main>
    </body>
  );
}
