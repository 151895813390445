import { cloneDeep } from '@apollo/client/utilities';
import { CompanyEntity, forCreate, forDelete, forUpdate } from './common/entity';
import dayjs from 'dayjs';
import { t } from './translation/Translator';
import { toMap } from '../util/map.util';

export enum CustomFieldType {
  text = 'text',
  date = 'date',
  bool = 'bool',
  listOfValues = 'listOfValues',
}

export enum CustomFieldEntityType {
  order = 'order',
  productTransaction = 'productTransaction',
  productMasterData = 'productMasterData',
  lot = 'lot',
  bin = 'bin',
}

export enum CustomFieldEntitySubType {
  inbound = 'inbound',
  outbound = 'outbound',
  replenish = 'replenish',
  move = 'move',
}

export enum CustomFieldEntityParentType {
  order = 'order',
}

enum CustomFieldValidationError {
  name = 'Please enter a name',
  type = 'Please select a type',
  entityType = 'Please select an entity type',
  alreadyPresentInListOfValues = 'Value is already in list of values',
}

export class CustomField extends CompanyEntity {
  type: CustomFieldType = CustomFieldType.text;
  entityType!: CustomFieldEntityType;
  entitySubtype?: CustomFieldEntitySubType;
  entityParentType?: CustomFieldEntityParentType;
  name!: string;
  mandatory: boolean = false;
  defaultValue?: string;
  index: number = 0;
  values: string[] = [];

  constructor(obj: any) {
    if (!obj.companyId) return;
    super(obj.companyId);
    Object.assign(this, cloneDeep(obj));
  }

  override forUpdate(): UpdateCustomFieldInput {
    return UpdateCustomFieldInput.from(this, UpdateCustomFieldInput);
  }

  override forDelete(): DeleteCustomFieldInput {
    return DeleteCustomFieldInput.from(this, DeleteCustomFieldInput);
  }

  override validate() {
    return this.validateEntity(
      Object.getOwnPropertyNames(this) as (keyof CustomField)[],
      (field: keyof CustomField) => {
        if (field === 'name' && !this.name) throw CustomFieldValidationError.name;
        if (field === 'type' && !this.type) throw CustomFieldValidationError.type;
        if (field === 'entityType' && !this.entityType) throw CustomFieldValidationError.entityType;
        return null;
      },
    );
  }

  validEntitySubtypes() {
    if (!this.entityType) return [];

    switch (this.entityType) {
      case CustomFieldEntityType.order:
        return [CustomFieldEntitySubType.inbound, CustomFieldEntitySubType.outbound];
      case CustomFieldEntityType.productTransaction:
        return [CustomFieldEntitySubType.inbound, CustomFieldEntitySubType.outbound];
      default:
        return [];
    }
  }

  validTypes() {
    if (!this.entityType) return [];

    switch (this.entityType) {
      case CustomFieldEntityType.productTransaction:
        return [CustomFieldType.bool, CustomFieldType.date, CustomFieldType.text];
      default:
        return [CustomFieldType.bool, CustomFieldType.date, CustomFieldType.text, CustomFieldType.listOfValues];
    }
  }

  validEntityParentTypes() {
    if (!this.entityType) return [];

    switch (this.entityType) {
      case CustomFieldEntityType.productTransaction:
        return [CustomFieldEntityParentType.order];
      default:
        return [];
    }
  }

  withName(name: string) {
    this.name = name;
    return this;
  }

  withType(type: CustomFieldType) {
    this.type = type;

    return cloneDeep(this);
  }

  withEntityType(entityType: CustomFieldEntityType) {
    this.entityType = entityType;
    if (this.entitySubtype && !this.validEntitySubtypes().includes(this.entitySubtype)) {
      this.entitySubtype = this.validEntitySubtypes()[0];
    }

    if (this.type && !this.validTypes().includes(this.type)) {
      this.type = this.validTypes()[0];
    }

    if (this.entityParentType && !this.validEntityParentTypes().includes(this.entityParentType)) {
      this.entityParentType = this.validEntityParentTypes().at(0);
    }
    return cloneDeep(this);
  }

  withEntitySubtype(entitySubtype?: CustomFieldEntitySubType | null) {
    this.entitySubtype = entitySubtype || undefined;
    return cloneDeep(this);
  }

  withEntityParentType(entityParentType?: CustomFieldEntityParentType) {
    this.entityParentType = entityParentType;
    return cloneDeep(this);
  }

  withMandatory(mandatory: boolean) {
    this.mandatory = mandatory;
    return this;
  }

  withDefaultValue(value?: string) {
    this.defaultValue = value;
    return cloneDeep(this);
  }

  withIndex(index: number) {
    this.index = index;
    return this;
  }

  withValues(values: string[]) {
    this.values = values;
    return cloneDeep(this);
  }

  addValue(value: string) {
    if (this.values.includes(value)) throw CustomFieldValidationError.alreadyPresentInListOfValues;
    this.values.push(value);
    return cloneDeep(this);
  }

  removeValue(value: string) {
    if (this.defaultValue === value) this.defaultValue = undefined;
    this.values = this.values.filter(v => v !== value);
    return cloneDeep(this);
  }

  toValue(value?: string | Date): CustomFieldValue {
    if (value instanceof Date) {
      value = dayjs(value).toISOString();
    }
    return {
      id: this.id,
      mandatory: this.mandatory,
      name: this.name,
      type: this.type,
      value: value ?? '',
    };
  }
}

export class CreateCustomFieldInput extends forCreate(CustomField) {}

export class UpdateCustomFieldInput extends forUpdate(CustomField) {}

export class DeleteCustomFieldInput extends forDelete(CustomField) {}

export function customFieldEntityTypeToString(field: CustomFieldEntityType): string {
  switch (field) {
    case CustomFieldEntityType.order:
      return t().order.singular.label;
    case CustomFieldEntityType.productTransaction:
      return t().productTransaction.singular.label;
    case CustomFieldEntityType.productMasterData:
      return t().productReferenceData.singular.label;
    case CustomFieldEntityType.lot:
      return t().lot.singular.label;
    case CustomFieldEntityType.bin:
      return t().bin.singular.label;
  }
}

export function customFieldEntitySubtypeToString(field?: CustomFieldEntitySubType): string {
  if (!field) return '';
  switch (field) {
    case CustomFieldEntitySubType.inbound:
      return t().inbound.singular.label;
    case CustomFieldEntitySubType.outbound:
      return t().outbound.singular.label;
    case CustomFieldEntitySubType.move:
      return t().move.singular.label;
    case CustomFieldEntitySubType.replenish:
      return t().replenish.singular.label;
  }
}

export function customFieldTypeToString(field: CustomFieldType): string {
  switch (field) {
    case CustomFieldType.bool:
      return 'Boolean';
    case CustomFieldType.date:
      return 'Date';
    case CustomFieldType.text:
      return 'Text';
    case CustomFieldType.listOfValues:
      return 'List of Values';
  }
}

export function customFieldEntityParentTypeToLocalizedString(entityParentType?: CustomFieldEntityParentType): string {
  if (!entityParentType) return '';
  switch (entityParentType) {
    case CustomFieldEntityParentType.order:
      return t().order.singular.label;
  }
}

export interface CustomFieldValue {
  id: string;
  name: string;
  value: string;
  mandatory: boolean;
  type: CustomFieldType;
}

export function getTextForCustomField(customFieldValue: CustomFieldValue): string {
  switch (customFieldValue.type) {
    case CustomFieldType.bool:
      return customFieldValue.value === 'true' ? 'True' : 'False';
    case CustomFieldType.date:
      const date = dayjs(customFieldValue.value);
      if (date.isValid()) {
        return date.toDate().toLocaleDateString();
      } else {
        return '';
      }
    case CustomFieldType.listOfValues:
    case CustomFieldType.text:
      return customFieldValue.value;
  }
}

export type CustomFieldValues = Map<string, CustomFieldValue> | CustomFieldValue[];
export type EntityWithCustomFields = CompanyEntity & { customFields?: CustomFieldValues };

export class WithCustomFields {
  customFields: Map<string, CustomFieldValue>;
  constructor(customFields: CustomFieldValues) {
    if (Array.isArray(customFields)) this.customFields = toMap(customFields, 'id');
    else this.customFields = customFields;
  }

  static toCustomFieldsMap(obj: EntityWithCustomFields): Map<string, CustomFieldValue> {
    if (obj.customFields && Array.isArray(obj.customFields)) {
      return new Map(toMap((obj as any).customFields || [], 'id'));
    } else if (obj.customFields) {
      return obj.customFields;
    } else {
      return new Map();
    }
  }

  customFieldValues(): CustomFieldValue[] {
    return [...this.customFields.values()];
  }

  withCustomField(value: CustomFieldValue) {
    this.customFields = new Map(this.customFields).set(value.id, {
      id: value.id,
      name: value.name,
      value: value.value,
      mandatory: value.mandatory,
      type: value.type,
    });
    return cloneDeep(this);
  }

  removeCustomField(id: string) {
    this.customFields.delete(id);
    return cloneDeep(this);
  }

  private asMap() {
    this.customFields;
  }
}
