import React from 'react';
import { CustomField, CustomFieldType, WithCustomFields } from '../../types/customField';
import TextInput from './TextInput';
import { Grid } from '@mui/material';
import Checkbox from '../../VentoryUI/components/common/Checkbox/Checkbox';
import DatePicker from './DatePicker';
import DropdownSelect from './DropdownSelect';
import { t } from '../../types/translation/Translator';
import { TranslationPluralType } from '../../types/translation/common';
import dayjs from 'dayjs';

interface CustomFieldInputProps {
  item: CustomField | undefined;
  value: any;
  entity: WithCustomFields;
  onChange: React.Dispatch<React.SetStateAction<any>>;
  disabled?: boolean;
}

export default function CustomFieldInput({ value, item, entity, onChange, disabled = false }: CustomFieldInputProps) {
  if (!item) return null;

  const key = item.name.charAt(0).toLowerCase().concat(item.name.substring(1).replaceAll(' ', ''));
  const label = ((t() as any)[key] as TranslationPluralType)?.singular.label;

  const handleChange = (item: CustomField, value: any) => {
    switch (item.type) {
      case CustomFieldType.text:
        return onChange(entity.withCustomField({ ...item, value: String(value) }));
      case CustomFieldType.bool:
        return onChange(entity.withCustomField({ ...item, value: String(value) }));
      case CustomFieldType.date:
        return onChange(entity.withCustomField({ ...item, value: new Date(value).toISOString() }));
      case CustomFieldType.listOfValues:
        if (value === null) {
          return onChange(entity.removeCustomField(item.id));
        } else {
          return onChange(entity.withCustomField({ ...item, value: String(value) }));
        }
    }
  };

  switch (item.type) {
    case CustomFieldType.text:
      return (
        <TextInput
          dynamicUpdate
          disabled={disabled}
          label={label || item.name}
          placeholder={label || item.name}
          mandatory={item.mandatory}
          value={value}
          onChange={v => handleChange(item, v)}
        />
      );
    case CustomFieldType.bool:
      return (
        <Grid container mt={1}>
          <Grid item xs={12} marginY={'auto'}>
            <Checkbox
              disabled={disabled}
              label={label || item.name}
              value={value === 'true' || false}
              onChange={v => handleChange(item, v)}
            />
          </Grid>
        </Grid>
      );
    case CustomFieldType.date:
      return (
        <DatePicker
          dynamicUpdate
          disabled={disabled}
          label={label || item.name}
          placeholder={label || item.name}
          mandatory={item.mandatory}
          value={dayjs(value) <= dayjs(0) ? undefined : new Date(value)}
          onChange={(val?: Date | undefined) => handleChange(item, val)}
        />
      );
    case CustomFieldType.listOfValues:
      return (
        <DropdownSelect
          mandatory={item.mandatory}
          label={label || item.name}
          placeholder={label || item.name}
          values={item.values}
          selectedValue={value || null}
          toText={item => item || ''}
          onChange={v => handleChange(item, v)}
          disabled={disabled}
        />
      );
  }
}
