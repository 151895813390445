import React, { useContext, useMemo, useState } from 'react';
import { t } from '../../../../../../types/translation/Translator';
import { Bin } from '../../../../../../types/bin';
import BinInfoPane from '../Panes/BinInfoPane';
import { Grid } from '@mui/material';
import { BinMutations, CreateBinResponse, CreateBinVariables } from '../../../../../../graphql/bin.graphql';
import { useMutation } from '@apollo/client';
import { BinContext } from '../../../../../../context/BinContext';
import { testIds } from '../../../../../../util/identifiers/identifiers.util';
import Modal from '../../../../../../VentoryUI/components/common/Modal/Modal';
import BackButton from '../../../../../../VentoryUI/components/common/Button/Templates/BackButton';
import CreateButton from '../../../../../../VentoryUI/components/common/Button/Templates/CreateButton';
import { CustomFieldEntityType, CustomFieldValue } from '../../../../../../types/customField';
import { CustomFieldContext } from '../../../../../../context/CustomFieldContext';

interface CreateBinModalInputProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  bin?: Bin;
}

export default function CreateBinModal({ open, setOpen, bin }: CreateBinModalInputProps) {
  const { bins, setBins } = useContext(BinContext);
  const { customFields } = useContext(CustomFieldContext);

  const [binInput, setBinInput] = useState<Bin>(new Bin(bin));

  const disabled = useMemo(() => {
    const existingCtxValues = [...customFields.values()]
      .filter(v => v.entityType === CustomFieldEntityType.bin)
      .map(cf => cf.toValue());
    const inputValues = binInput.customFieldValues();
    const customFieldValues: CustomFieldValue[] = Array.from(
      new Map([...existingCtxValues, ...inputValues].map(cfv => [cfv.id, cfv])).values(),
    );

    const missingRequiredField = (cfv: CustomFieldValue) => cfv.mandatory && !cfv.value && true;
    const hasMissingRequiredCustomFields = customFieldValues.some(missingRequiredField);
    if (!binInput.name || !binInput.binStatusId || hasMissingRequiredCustomFields) {
      return true;
    } else {
      return false;
    }
  }, [binInput]);

  const [error, setError] = useState<string>('');

  const [create, { loading }] = useMutation<CreateBinResponse, CreateBinVariables>(BinMutations.create, {
    onCompleted: res => {
      const bin = res.createBin[0];
      bins.set(bin.id, new Bin(bin));
      setBins(new Map(bins));
      handleClose();
    },
    onError: res => setError(res.message),
  });

  if (!bin) return null; // TODO: Not found

  const handleCreate = async (bin: Bin) => {
    try {
      const binInput = bin.forCreate();

      await create({
        variables: {
          bins: [binInput],
        },
      });
    } catch (e) {
      setError(String(e));
    }
  };

  const handleClose = () => {
    setBinInput(new Bin(bin));
    setOpen(false);
    setError('');
  };

  const footer = (bin: Bin) => (
    <Grid container marginTop={'auto'} columnSpacing={1} justifyContent={'flex-end'}>
      <Grid item>
        <BackButton disabled={loading} onClick={handleClose} />
      </Grid>
      <Grid item>
        <CreateButton loading={loading} disabled={disabled} onClick={() => handleCreate(bin)} />
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={open}
      error={error}
      onClose={handleClose}
      title={t().createBin.singular.label}
      testId={testIds.createBinModal}
    >
      <BinInfoPane binInput={binInput} setBinInput={setBinInput} footer={footer} />
    </Modal>
  );
}
