import React from 'react';
import { Tag } from '../../../../../types/tag';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { t } from '../../../../../types/translation/Translator';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import TagFilterDropdownContent from '../../DynamicEntityFilter/Content/TagFilterContent';
import TagFilterInnerContent from '../../DynamicEntityFilter/InnerContent/TagFilterInnerContent';
import { toFilterString } from '../../../../../util/string.util';
import { FilterProps } from '../common/filter.util';
import { TagRelation } from '../../../../../types/tagRelation';
import { EntityType } from '../../../../../types/comment';

export class TagFilter extends BaseFilter<Tag> {
  toLabel(): string {
    return t().tag.plural.label;
  }

  toIcon(): React.JSX.Element {
    return <LocalOfferOutlinedIcon sx={{ width: '15px', height: '15px' }} />;
  }

  toDropdownContent(props: BaseFilterProps): React.JSX.Element {
    return (
      <TagFilterDropdownContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    return (
      <TagFilterInnerContent filter={this} filterProps={props.filterProps} setFilterProps={props.setFilterProps} />
    );
  }

  toCount(props: BaseFilterProps): number {
    return props.filterProps.tags?.size || 0;
  }

  search(item: Tag, textFilter: string) {
    const textFilterString = toFilterString(textFilter);

    if (toFilterString(item.name).includes(textFilterString)) {
      return true;
    }

    return false;
  }

  /**
   * @returns {Set<string>}
   * - Set containing ids of allowed entities if any permitted by tagRelations.
   * - Set containing tags selected in filter if none permitted by tagRelations.
   * - Empty Set if:
   *    - No Tags (TagRelations) set in FilterProps
   *    - Entity provided is not limited by Tags logic (tag filtering not applicable)
   */
  static filterByTag(
    tagRelations: Map<string, TagRelation>,
    entity: EntityType,
    filterProps: FilterProps,
  ): Set<string> {
    if (!filterProps.tags?.size) return new Set();

    if (entity === EntityType.stockLocation) {
      const allowedStockLocations = [...tagRelations.values()]
        .filter(tr => tr.type === entity && filterProps.tags?.has(tr.tagId))
        .map(tr => tr.entityId);

      if (!allowedStockLocations.length && filterProps.tags.size) return filterProps.tags;
      return new Set(allowedStockLocations);
    }

    return new Set();
  }
}
