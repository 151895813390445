import { Order } from '../../../../../types/order';
import { StockLocation } from '../../../../../types/stockLocation';
import { OrderTableSettings } from '../../../../../types/orderTableSettings';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { FilterProps, FilterStorageKey } from '../common/filter.util';
import dayjs from 'dayjs';
import { orderTableFilter } from '../../../../../components/Common/OrderTable';
import { OrderStatusFilter } from './OrderStatusFilter';
import { OrderTypeFilter } from './OrderTypeFilter';
import { StockLocationFilter } from '../common/StockLocationFilter';
import { DateTimeFilter } from '../common/DateTimeFilter';
import { TagRelation } from '../../../../../types/tagRelation';
import { EntityType } from '../../../../../types/comment';
import { TagFilter } from '../Tag/TagFilter';
import { toFilterString } from '../../../../../util/string.util';

export class OrderFilter extends BaseFilter<Order> {
  stockLocations: Map<string, StockLocation>;
  tableSettings: Map<string, OrderTableSettings>;
  tagRelations: Map<string, TagRelation>;

  constructor(
    stockLocations: Map<string, StockLocation>,
    tableSettings: Map<string, OrderTableSettings>,
    tagRelations: Map<string, TagRelation>,
  ) {
    super(FilterStorageKey.ORDER);
    this.stockLocations = stockLocations;
    this.tableSettings = tableSettings;
    this.tagRelations = tagRelations;
  }

  filterOptions = () => [
    new DateTimeFilter(),
    new StockLocationFilter(this.tagRelations),
    new OrderStatusFilter(this.stockLocations, this.tableSettings),
    new OrderTypeFilter(this.stockLocations, this.tableSettings),
    new TagFilter(),
  ];

  filteredItems(items: Order[], query: string, filterProps: FilterProps): Order[] {
    const filteredStockLocations = TagFilter.filterByTag(this.tagRelations, EntityType.stockLocation, filterProps);

    const result = items.filter(item => {
      if (filterProps.orderType && filterProps.orderType.size && !filterProps.orderType.has(item.type)) return false;

      if (filterProps.orderStatus && filterProps.orderStatus.size && !filterProps.orderStatus.has(item.status))
        return false;

      if (
        filterProps.stockLocation &&
        filterProps.stockLocation.size &&
        !filterProps.stockLocation.has(item.stockLocationId)
      ) {
        return false;
      }

      if (
        filterProps.timeFrame &&
        (dayjs(filterProps.timeFrame.start).startOf('day').isAfter(dayjs(item.createdAt)) ||
          dayjs(filterProps.timeFrame.end).endOf('day').isBefore(dayjs(item.createdAt)))
      ) {
        return false;
      }

      if (filteredStockLocations.size && !filteredStockLocations.has(item.stockLocationId)) {
        return false;
      }

      return this.search(item, query);
    });

    return result.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());
  }

  search(item: Order, filter: string) {
    const textFilterString = toFilterString(filter);

    const defaultOrderTableSettings = new OrderTableSettings(
      [...this.tableSettings.values()][0] || { companyId: item.companyId },
    );

    return orderTableFilter(
      item,
      textFilterString,
      this.stockLocations,
      this.tableSettings.get(item.companyId) || defaultOrderTableSettings,
    );
  }

  toLabel(props: BaseFilterProps): string {
    throw new Error('Method not implemented.');
  }

  toInnerContent(props: BaseFilterProps): string | JSX.Element {
    throw new Error('Method not implemented.');
  }

  toDropdownContent(props: BaseFilterProps): React.JSX.Element {
    throw new Error('Method not implemented.');
  }

  toIcon(props: BaseFilterProps): React.JSX.Element {
    throw new Error('Method not implemented.');
  }

  toCount(props: BaseFilterProps): number {
    throw new Error('Method not implemented.');
  }
}
