import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { ProductMasterData, getDefaultUnit } from '../../../../types/productMasterData';
import TextInput from '../../../Common/TextInput';
import { t } from '../../../../types/translation/Translator';
import DropdownSelect from '../../../Common/DropdownSelect';
import { countries } from '../../../../util/country';
import Checkbox from '../../../../VentoryUI/components/common/Checkbox/Checkbox';
import Dropdown from '../../../Common/Dropdown';
import {
  ImperialLengthUnit,
  ImperialMassUnit,
  ImperialVolumeUnit,
  MetricUnit,
  Unit,
  UnitSystem,
  UnitType,
  needsUnit,
  unitSystemToString,
  unitTypeToString,
} from '../../../../types/unitOfMeasure';
import { ProductMasterDataContext } from '../../../../context/ProductMasterDataContext';
import {
  ProductMasterDataMutations,
  UpdateProductMasterDataResponse,
  UpdateProductMasterDataVariables,
} from '../../../../graphql/productMasterData.graphql';
import { useMutation } from '@apollo/client';
import { CompanyContext } from '../../../../context/CompanyContext';
import { testIds } from '../../../../util/identifiers/identifiers.util';

import Paper from '../../../../VentoryUI/components/common/Paper/Paper';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import { CreateFilesResponse, CreateFilesVariables, FileMutations } from '../../../../graphql/file.graphql';
import { FileContext } from '../../../../context/FileContext';
import ImagePicker from '../../../../VentoryUI/components/common/File/Image/ImagePicker';
import CustomFieldInput from '../../../Common/CustomFieldInput';
import { CustomFieldContext } from '../../../../context/CustomFieldContext';
import { CustomFieldEntityType, WithCustomFields } from '../../../../types/customField';

interface ProductMasterDataInfoPaneInputProps {
  footer: (input: ProductMasterData) => JSX.Element;
  productMasterDataInput: ProductMasterData;
  setProductMasterDataInput: (pmd: ProductMasterData) => void;
  title?: string;
  setError: (error: string) => void;
}

export default function ProductMasterDataInfoPane({
  footer,
  productMasterDataInput,
  setProductMasterDataInput,
  title,
  setError,
}: ProductMasterDataInfoPaneInputProps) {
  const { productMasterData, setProductMasterData } = useContext(ProductMasterDataContext);
  const { customFields } = useContext(CustomFieldContext);
  const { currentCompany } = useContext(CompanyContext);

  const { files, setFiles } = useContext(FileContext);

  const [createFile, { loading: createFileLoading }] = useMutation<CreateFilesResponse, CreateFilesVariables>(
    FileMutations.create,
    {
      onError: error => setError(error.message),
    },
  );

  const [update, { loading: productMasterDataUpdateLoading }] = useMutation<
    UpdateProductMasterDataResponse,
    UpdateProductMasterDataVariables
  >(ProductMasterDataMutations.update, {
    onCompleted: res => {
      const updatedProductMasterData = res.updateProductMasterData[0];
      setProductMasterData(
        new Map(productMasterData).set(updatedProductMasterData.id, new ProductMasterData(updatedProductMasterData)),
      );
      setProductMasterDataInput(new ProductMasterData(updatedProductMasterData));
    },
  });

  const customFieldItems = [...customFields.values()].filter(
    v => v.entityType === CustomFieldEntityType.productMasterData,
  );
  const inputValues = productMasterDataInput.customFieldValues();
  const defaultValues = customFieldItems.map(cf => cf.toValue());
  const customFieldValues = inputValues.length ? inputValues : defaultValues;

  const unitSuffix = (system: UnitSystem, type: UnitType) => {
    if (system === UnitSystem.metric) {
      if (
        type === UnitType.quantity ||
        type === UnitType.unit ||
        type === UnitType.day ||
        type === UnitType.set ||
        type === UnitType.m2 ||
        type === UnitType.m3 ||
        type === UnitType.pack ||
        type === UnitType.hour ||
        type === UnitType.vnt
      ) {
        return '';
      }

      switch (type) {
        case UnitType.length:
          return t().meter.singular.label;
        case UnitType.mass:
          return t().gram.singular.label;
        case UnitType.volume:
          return t().liter.singular.label;
        default:
          return '';
      }
    } else {
      return '';
    }
  };

  const unitText = (item: Unit) => {
    const system = productMasterDataInput.unitOfMeasure?.system;
    const type = productMasterDataInput.unitOfMeasure?.unitType;
    if (!type || !system) return '';

    if (system === UnitSystem.metric) {
      const text = item === MetricUnit.base ? `${unitSuffix(system, type)}` : `${item}${unitSuffix(system, type)}`;
      return text.charAt(0).toUpperCase() + text.slice(1);
    }

    if (item === ImperialVolumeUnit.fluidOunce) return 'Ounce';
    return String(item).charAt(0).toUpperCase() + String(item).slice(1);
  };

  const unitValues = () => {
    const system = productMasterDataInput.unitOfMeasure?.system;
    const type = productMasterDataInput.unitOfMeasure?.unitType;

    if (system === UnitSystem.metric) {
      return [...Object.keys(MetricUnit)];
    }

    if (type === UnitType.length) {
      return [...Object.keys(ImperialLengthUnit)];
    } else if (type === UnitType.mass) {
      return [...Object.keys(ImperialMassUnit)];
    } else if (type === UnitType.volume) {
      return [...Object.keys(ImperialVolumeUnit)];
    }

    return [];
  };

  return (
    <FlexPane
      testId={testIds.productMasterDataInfoPane}
      content={
        <Paper>
          <Grid container rowSpacing={2} columnSpacing={2}>
            {title ? (
              <Grid item xs={12}>
                <p className='font-semibold text-xl'>{title}</p>
              </Grid>
            ) : null}
            {/* Column 1 */}
            <Grid item xs={4}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    mandatory
                    disabled={productMasterDataInput.externalIdentifier != undefined}
                    value={productMasterDataInput.productNumber}
                    label={t().productNumber.singular.label}
                    placeholder={t().productNumber.singular.label}
                    onChange={v => setProductMasterDataInput(productMasterDataInput.withProductNumber(v))}
                    testId={testIds.productNumber}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    mandatory
                    disabled={productMasterDataInput.externalIdentifier != undefined}
                    value={productMasterDataInput.productName}
                    label={t().productName.singular.label}
                    placeholder={t().productName.singular.label}
                    onChange={v => setProductMasterDataInput(productMasterDataInput.withProductName(v))}
                    testId={testIds.productName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    disabled={productMasterDataInput.externalIdentifier != undefined}
                    value={productMasterDataInput.globalTradeItemNumber}
                    label={t().globalTradeItemNumber.singular.label.toUpperCase()}
                    placeholder={t().globalTradeItemNumber.singular.label.toUpperCase()}
                    onChange={v => productMasterDataInput.withEAN(v)}
                    testId={testIds.originalEquipmentManufacturerCode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DropdownSelect
                    disabled={productMasterDataInput.externalIdentifier != undefined}
                    testId={testIds.countryOfOrigin}
                    placeholder={t().countryOfOrigin.singular.label}
                    label={t().countryOfOrigin.singular.label}
                    selectedValue={countries.find(c => c.cca2 === productMasterDataInput.countryOfOrigin) || null}
                    maxHeight='150px'
                    values={countries}
                    toText={(item: any) => item.name.common}
                    onChange={(item: any) =>
                      setProductMasterDataInput(
                        productMasterDataInput.withCountryOfOrigin(item ? item.cca2 : undefined),
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container mt={2}>
                    <Grid item xs={6}>
                      <Checkbox
                        disabled={productMasterDataInput.externalIdentifier != undefined}
                        label={t().serialManaged.singular.label}
                        onChange={v => productMasterDataInput.withSerialManaged(v)}
                        value={productMasterDataInput.serialManaged || false}
                        testId={testIds.serialManaged}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Checkbox
                        disabled={productMasterDataInput.externalIdentifier != undefined}
                        label={t().lpnManaged.singular.label}
                        onChange={v => productMasterDataInput.withLpnManaged(v)}
                        value={productMasterDataInput.lpnManaged || false}
                        testId={testIds.lpnManaged}
                      />
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <Checkbox
                        disabled={productMasterDataInput.externalIdentifier != undefined}
                        label={t().lotManaged.singular.label}
                        onChange={v => productMasterDataInput.withLotManaged(v)}
                        value={productMasterDataInput.lotManaged || false}
                        testId={testIds.lotManaged}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container mt={2}>
                    <Grid item xs={12}>
                      <Dropdown
                        disabled={productMasterDataInput.externalIdentifier != undefined}
                        testId={testIds.unitType}
                        label={t().unitOfMeasure.singular.label}
                        values={[...Object.keys(UnitType)] as UnitType[]}
                        selectedValue={productMasterDataInput.unitOfMeasure?.unitType || UnitType.quantity}
                        toText={item => unitTypeToString(item)}
                        onChange={item => setProductMasterDataInput(productMasterDataInput.withUnitType(item))}
                      />
                    </Grid>
                    {productMasterDataInput.unitOfMeasure?.unitType &&
                    !needsUnit(productMasterDataInput.unitOfMeasure?.unitType) ? (
                      <>
                        <Grid item xs={12}>
                          <Dropdown
                            disabled={productMasterDataInput.externalIdentifier != undefined}
                            testId={testIds.unitSystem}
                            values={[...Object.keys(UnitSystem)] as UnitSystem[]}
                            selectedValue={productMasterDataInput.unitOfMeasure?.system || UnitSystem.metric}
                            toText={item => unitSystemToString(item)}
                            onChange={item => setProductMasterDataInput(productMasterDataInput.withUnitSystem(item))}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Dropdown
                            disabled={productMasterDataInput.externalIdentifier != undefined}
                            testId={testIds.unit}
                            maxHeight='200px'
                            values={unitValues()}
                            selectedValue={
                              productMasterDataInput.unitOfMeasure?.unit ||
                              getDefaultUnit(
                                productMasterDataInput.unitOfMeasure!.system,
                                productMasterDataInput.unitOfMeasure!.unitType,
                              )
                            }
                            toText={item => unitText(item)}
                            onChange={item => setProductMasterDataInput(productMasterDataInput.withUnit(item))}
                          />
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={4} item>
              <p className='text-[14px] mb-1 text-sm font-medium text-slate-800'>{t().productImage.singular.label}</p>
              {productMasterDataInput.id ? (
                <ImagePicker
                  width='100%'
                  onRemove={() => setProductMasterDataInput(productMasterDataInput.withImage(undefined))}
                  fileId={productMasterDataInput.image || ''}
                  onSelected={selected => setProductMasterDataInput(productMasterDataInput.withImage(selected))}
                  ids={[...files.values()]
                    .filter(f => f.fileTypeCategory === 'image' && f.entityId === productMasterDataInput.id)
                    .map(f => f.id)}
                />
              ) : null}
            </Grid>
            <Grid item xs={4}>
              {customFieldValues
                .sort((c1, c2) => c1.name.localeCompare(c2.name))
                .map(cf => (
                  <Grid item mb={2} key={`custom_field_input${cf.id}`}>
                    <CustomFieldInput
                      disabled={productMasterDataInput.externalIdentifier != undefined}
                      value={WithCustomFields.toCustomFieldsMap(productMasterDataInput).get(cf.id)?.value}
                      entity={productMasterDataInput}
                      item={customFields.get(cf.id)}
                      onChange={setProductMasterDataInput}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Paper>
      }
      footer={
        <Grid container>
          <Grid item xs={12} alignContent={'flex-end'}>
            {footer(productMasterDataInput)}
          </Grid>
        </Grid>
      }
    />
  );
}
