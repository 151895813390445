import React from 'react';
import { ProductTransaction, ProductTransactionType } from '../../types/productTransaction';
import { StockLocation } from '../../types/stockLocation';
import ProductTransactionStatusLabel from './ProductTransactionStatusLabel';
import { ProductTransactionColumn, ProductTransactionTableSettings } from '../../types/productTransactionTableSettings';
import { toMap } from '../../util/map.util';
import { removeDiacritics, toFilterString } from '../../util/string.util';
import { Bin } from '../../types/bin';
import { ProductMasterData } from '../../types/productMasterData';
import { Lot } from '../../types/lot';
import { SystemUser, User } from '../../types/user';
import { quantityWithSuffix, toQuantityString } from '../../types/unitOfMeasure';
import { getTextForCustomField } from '../../types/customField';
import { TableHeader } from '../../VentoryUI/components/common/Table/Table';
import { t } from '../../types/translation/Translator';

export const productTransactionTableHeaders = (
  settings: ProductTransactionTableSettings,
  stockLocations: Map<string, StockLocation>,
  bins: Map<string, Bin>,
  productMasterData: Map<string, ProductMasterData>,
  lots: Map<string, Lot>,
  users: Map<string, User>,
) => {
  const wantedColumns = toMap(settings.columns, 'column');

  const columns: TableHeader<ProductTransaction>[] = [];

  if (wantedColumns.has(ProductTransactionColumn.type)) {
    columns.push({
      key: ProductTransactionColumn.type,
      column: ProductTransactionColumn.type,
      name: wantedColumns.get(ProductTransactionColumn.type)?.label || '',
      text: (item: ProductTransaction) => (item.type == ProductTransactionType.inbound ? 'Inbound' : 'Outbound'),
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.createdAt)) {
    columns.push({
      key: ProductTransactionColumn.createdAt,
      column: ProductTransactionColumn.createdAt,
      name: wantedColumns.get(ProductTransactionColumn.createdAt)?.label || '',
      text: (item: ProductTransaction) => `${new Date(item.createdAt).toLocaleDateString()}`,
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.updatedAt)) {
    columns.push({
      key: ProductTransactionColumn.updatedAt,
      column: ProductTransactionColumn.updatedAt,
      name: wantedColumns.get(ProductTransactionColumn.updatedAt)?.label || '',
      text: (item: ProductTransaction) => `${new Date(item.updatedAt).toLocaleDateString()}`,
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.sourceStockLocation)) {
    columns.push({
      key: ProductTransactionColumn.sourceStockLocation,
      column: ProductTransactionColumn.sourceStockLocation,
      name: wantedColumns.get(ProductTransactionColumn.sourceStockLocation)?.label || '',
      text: (item: ProductTransaction) => {
        if (item.type === ProductTransactionType.inbound || !item.product.fromStockLocationId) return '';
        return stockLocations.get(item.product.fromStockLocationId)?.name || t().unknownStockLocation.singular.label;
      },
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.destinationStockLocation)) {
    columns.push({
      key: ProductTransactionColumn.destinationStockLocation,
      column: ProductTransactionColumn.destinationStockLocation,
      name: wantedColumns.get(ProductTransactionColumn.destinationStockLocation)?.label || '',
      text: (item: ProductTransaction) => {
        if (item.type === ProductTransactionType.outbound || !item.product.toStockLocationId) return '';
        return stockLocations.get(item.product.toStockLocationId)?.name || t().unknownStockLocation.singular.label;
      },
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.sourceBin)) {
    columns.push({
      key: ProductTransactionColumn.sourceBin,
      column: ProductTransactionColumn.sourceBin,
      name: wantedColumns.get(ProductTransactionColumn.sourceBin)?.label || '',
      text: (item: ProductTransaction) => {
        if (item.type === ProductTransactionType.inbound || !item.product.fromBinId) return '';
        return bins.get(item.product.fromBinId)?.name || t().unknownBin.singular.label;
      },
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.destinationBin)) {
    columns.push({
      key: ProductTransactionColumn.destinationBin,
      column: ProductTransactionColumn.destinationBin,
      name: wantedColumns.get(ProductTransactionColumn.destinationBin)?.label || '',
      text: (item: ProductTransaction) => {
        if (item.type === ProductTransactionType.outbound || !item.product.toBinId) return '';
        return bins.get(item.product.toBinId)?.name || t().unknownBin.singular.label;
      },
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.productName)) {
    columns.push({
      key: ProductTransactionColumn.productName,
      column: ProductTransactionColumn.productName,
      name: wantedColumns.get(ProductTransactionColumn.productName)?.label || '',
      text: (item: ProductTransaction) => {
        return productMasterData.get(item.product.pmdId ?? '')?.productName || t().unknownProduct.singular.label;
      },
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.productNumber)) {
    columns.push({
      key: ProductTransactionColumn.productNumber,
      column: ProductTransactionColumn.productNumber,
      name: wantedColumns.get(ProductTransactionColumn.productNumber)?.label || '',
      text: (item: ProductTransaction) => {
        return productMasterData.get(item.product.pmdId ?? '')?.productNumber || t().unknownProduct.singular.label;
      },
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.lot)) {
    columns.push({
      key: ProductTransactionColumn.productNumber,
      column: ProductTransactionColumn.productNumber,
      name: wantedColumns.get(ProductTransactionColumn.productNumber)?.label || '',
      text: (item: ProductTransaction) => {
        if (!item.product.lotId) return '';
        return lots.get(item.product.lotId)?.number || t().unknownLot.singular.label;
      },
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.lpn)) {
    columns.push({
      key: ProductTransactionColumn.lpn,
      column: ProductTransactionColumn.lpn,
      name: wantedColumns.get(ProductTransactionColumn.lpn)?.label || '',
      text: (item: ProductTransaction) => {
        return item.product.lpn ?? '';
      },
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.serialNbr)) {
    columns.push({
      key: ProductTransactionColumn.serialNbr,
      column: ProductTransactionColumn.serialNbr,
      name: wantedColumns.get(ProductTransactionColumn.serialNbr)?.label || '',
      text: (item: ProductTransaction) => {
        return item.product.serialNbr ?? '';
      },
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.countryOfOrigin)) {
    columns.push({
      key: ProductTransactionColumn.countryOfOrigin,
      column: ProductTransactionColumn.countryOfOrigin,
      name: wantedColumns.get(ProductTransactionColumn.countryOfOrigin)?.label || '',
      text: (item: ProductTransaction) => {
        return item.product.countryOfOrigin ?? '';
      },
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.processedAt)) {
    columns.push({
      key: ProductTransactionColumn.processedAt,
      column: ProductTransactionColumn.processedAt,
      name: wantedColumns.get(ProductTransactionColumn.processedAt)?.label || '',
      text: (item: ProductTransaction) => {
        if (!item.processedAt) return '';
        return `${new Date(item.processedAt).toLocaleDateString()} - ${new Date(
          item.processedAt,
        ).toLocaleTimeString()}`;
      },
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.processedBy)) {
    columns.push({
      key: ProductTransactionColumn.processedBy,
      column: ProductTransactionColumn.processedBy,
      name: wantedColumns.get(ProductTransactionColumn.processedBy)?.label || '',
      text: (item: ProductTransaction) => {
        if (!item.processedBy) return '';
        return (
          users.get(item.processedBy)?.email || (item.processedBy === SystemUser.id ? SystemUser.email : 'Unknown User')
        );
      },
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.quantity)) {
    columns.push({
      key: ProductTransactionColumn.quantity,
      column: ProductTransactionColumn.quantity,
      name: wantedColumns.get(ProductTransactionColumn.quantity)?.label || '',
      text: (item: ProductTransaction) => {
        const pmd = productMasterData.get(item.product.pmdId ?? '');
        return quantityWithSuffix(item.product.quantity.toString(), pmd?.unitOfMeasure);
      },
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.processedQuantity)) {
    columns.push({
      key: ProductTransactionColumn.processedQuantity,
      column: ProductTransactionColumn.processedQuantity,
      name: wantedColumns.get(ProductTransactionColumn.processedQuantity)?.label || '',
      text: (item: ProductTransaction) => {
        if (!item.product.processedQuantity) return '';
        return toQuantityString(
          item.product.processedQuantity.toString(),
          productMasterData.get(item.product.pmdId ?? '')?.unitOfMeasure,
        );
      },
    });
  }

  if (wantedColumns.has(ProductTransactionColumn.status)) {
    columns.push({
      key: ProductTransactionColumn.status,
      column: ProductTransactionColumn.status,
      name: wantedColumns.get(ProductTransactionColumn.status)?.label || '',

      text: (item: ProductTransaction) => (
        <ProductTransactionStatusLabel
          status={item.status}
          justify={
            wantedColumns.size - 1 === wantedColumns.get(ProductTransactionColumn.status)?.index ? 'end' : 'start'
          }
        />
      ),
    });
  }

  const defaultKeys = new Set(Object.keys(ProductTransactionColumn));
  const customFieldColumns = [...wantedColumns.values()].filter(item => !defaultKeys.has(item.column));

  customFieldColumns.forEach(item => {
    columns.push({
      key: item.column,
      name: item.label,
      column: item.column,
      text: (pt: ProductTransaction) => {
        const customFieldValue = pt.customFieldValues().find(cf => cf.name === item.column);
        if (!customFieldValue) return '';

        return getTextForCustomField(customFieldValue);
      },
    });
  });

  return columns.sort(
    (a: TableHeader<ProductTransaction>, b: TableHeader<ProductTransaction>) =>
      (wantedColumns.get(a.column || '')?.index || -1) - (wantedColumns.get(b.column || '')?.index || -1),
  );
};

export const productTransactionTableFilter = (
  item: ProductTransaction,
  filter: string,
  stockLocations: Map<string, StockLocation>,
  bins: Map<string, Bin>,
  productMasterData: Map<string, ProductMasterData>,
  lots: Map<string, Lot>,
  users: Map<string, User>,
  settings: ProductTransactionTableSettings,
) => {
  const filterString = removeDiacritics(toFilterString(filter));
  const wantedColumns = toMap(settings.columns, 'column');

  if (!filter) return true;

  const pmd = productMasterData.get(item.product.pmdId ?? '');

  if (pmd) {
    if (
      wantedColumns.has(ProductTransactionColumn.productName) &&
      removeDiacritics(toFilterString(pmd.productName)).includes(filterString)
    ) {
      return true;
    }

    if (
      wantedColumns.has(ProductTransactionColumn.productNumber) &&
      removeDiacritics(toFilterString(pmd.productNumber)).includes(filterString)
    ) {
      return true;
    }
  }

  if (wantedColumns.has(ProductTransactionColumn.status) && toFilterString(item.status).includes(filterString)) {
    return true;
  }

  if (wantedColumns.has(ProductTransactionColumn.type) && toFilterString(item.type).includes(filterString)) {
    return true;
  }

  if (
    wantedColumns.has(ProductTransactionColumn.createdAt) &&
    new Date(item.createdAt).toLocaleDateString().toLocaleLowerCase().includes(filterString)
  ) {
    return true;
  }

  if (
    wantedColumns.has(ProductTransactionColumn.createdBy) &&
    (removeDiacritics(toFilterString(users.get(item.createdBy)?.email)) ||
      removeDiacritics(
        toFilterString(`${users.get(item.createdBy)?.firstName} ${users.get(item.createdBy)?.lastName}`),
      ))
  )
    if (
      wantedColumns.has(ProductTransactionColumn.updatedAt) &&
      new Date(item.updatedAt).toLocaleDateString().toLocaleLowerCase().includes(filterString)
    ) {
      return true;
    }

  if (
    wantedColumns.has(ProductTransactionColumn.updatedBy) &&
    (removeDiacritics(toFilterString(users.get(item.updatedBy)?.email)) ||
      removeDiacritics(
        toFilterString(`${users.get(item.updatedBy)?.firstName} ${users.get(item.updatedBy)?.lastName}`),
      ))
  ) {
    return true;
  }
  if (
    wantedColumns.has(ProductTransactionColumn.processedAt) &&
    item.processedAt &&
    new Date(item.processedAt).toLocaleDateString().toLocaleLowerCase().includes(filterString)
  ) {
    return true;
  }

  if (
    wantedColumns.has(ProductTransactionColumn.processedBy) &&
    item.processedBy &&
    (removeDiacritics(toFilterString(users.get(item.processedBy)?.email)) ||
      removeDiacritics(
        toFilterString(`${users.get(item.processedBy)?.firstName} ${users.get(item.processedBy)?.lastName}`),
      ))
  ) {
    return true;
  }

  if (
    wantedColumns.has(ProductTransactionColumn.destinationStockLocation) &&
    item.type === ProductTransactionType.inbound &&
    item.product.toStockLocationId &&
    removeDiacritics(toFilterString(stockLocations.get(item.product.toStockLocationId)?.name)).includes(filterString)
  ) {
    return true;
  }

  if (
    wantedColumns.has(ProductTransactionColumn.sourceStockLocation) &&
    item.type === ProductTransactionType.outbound &&
    item.product.fromStockLocationId &&
    removeDiacritics(toFilterString(stockLocations.get(item.product.fromStockLocationId)?.name)).includes(filterString)
  ) {
    return true;
  }

  if (
    wantedColumns.has(ProductTransactionColumn.sourceBin) &&
    item.type === ProductTransactionType.outbound &&
    item.product.fromBinId &&
    removeDiacritics(toFilterString(bins.get(item.product.fromBinId)?.name)).includes(filterString)
  ) {
    return true;
  }

  if (
    wantedColumns.has(ProductTransactionColumn.destinationBin) &&
    item.type === ProductTransactionType.inbound &&
    item.product.toBinId &&
    removeDiacritics(toFilterString(bins.get(item.product.toBinId)?.name)).includes(filterString)
  ) {
    return true;
  }

  if (
    wantedColumns.has(ProductTransactionColumn.lot) &&
    item.product.lotId &&
    removeDiacritics(toFilterString(lots.get(item.product.lotId)?.number))
  ) {
    return true;
  }

  if (
    wantedColumns.has(ProductTransactionColumn.serialNbr) &&
    item.product.serialNbr &&
    removeDiacritics(toFilterString(item.product.serialNbr)).includes(filterString)
  ) {
    return true;
  }

  if (
    wantedColumns.has(ProductTransactionColumn.lpn) &&
    item.product.lpn &&
    removeDiacritics(toFilterString(item.product.lpn)).includes(filterString)
  ) {
    return true;
  }

  if (
    wantedColumns.has(ProductTransactionColumn.countryOfOrigin) &&
    item.product.countryOfOrigin &&
    removeDiacritics(toFilterString(item.product.countryOfOrigin)).includes(filterString)
  ) {
    return true;
  }

  const defaultKeys = new Set(Object.keys(ProductTransactionColumn));
  const customFieldColumns = [...wantedColumns.values()].filter(item => !defaultKeys.has(item.column));

  for (const custom of customFieldColumns) {
    const field = item
      .customFieldValues()
      .find(i => i.name === custom.column)
      ?.value.toLocaleLowerCase();
    if (field?.includes(filterString)) return true;
  }

  return false;
};
