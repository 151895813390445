import React from 'react';
import { BinStatus } from '../../../../../types/binStatus';
import { EntityType } from '../../../../../types/comment';
import { Product } from '../../../../../types/product';
import { ProductMasterData } from '../../../../../types/productMasterData';
import { TagRelation } from '../../../../../types/tagRelation';
import { t } from '../../../../../types/translation/Translator';
import { toFilterString } from '../../../../../util/string.util';
import { ProductIcon } from '../../../../icons/Product/ProductIcon';
import { BinFilter } from '../Bin/BinFilter';
import { BaseFilter, BaseFilterProps } from '../common/BaseFilter';
import { FilterStorageKey, FilterProps } from '../common/filter.util';
import { StockLocationFilter } from '../common/StockLocationFilter';
import { TagFilter } from '../Tag/TagFilter';

export class ProductFilter extends BaseFilter<Product> {
  productMasterData: Map<string, ProductMasterData>;
  binStatuses: Map<string, BinStatus>;
  tagRelations: Map<string, TagRelation>;

  constructor(
    productMasterData: Map<string, ProductMasterData>,
    binStatuses: Map<string, BinStatus>,
    tagRelations: Map<string, TagRelation>,
  ) {
    super(FilterStorageKey.PRODUCT);
    this.productMasterData = productMasterData;
    this.binStatuses = binStatuses;
    this.tagRelations = tagRelations;
  }

  filterOptions = () => [
    new StockLocationFilter(this.tagRelations),
    new BinFilter(this.binStatuses, this.tagRelations),
    new TagFilter(),
  ];

  toLabel = () => {
    return t().product.singular.label;
  };

  toCount(props: BaseFilterProps) {
    return props.filterProps.product?.size || 0;
  }

  toDropdownContent(props: BaseFilterProps) {
    return <></>;
  }

  toInnerContent = (props: BaseFilterProps) => {
    return <></>;
  };

  toIcon = () => {
    return <ProductIcon className={this.className} />;
  };

  search = (item: Product, filter: string) => {
    const textFilterString = toFilterString(filter);

    const productName = this.productMasterData.get(item.productMasterDataId)?.productName;
    const productNumber = this.productMasterData.get(item.productMasterDataId)?.productNumber;

    if (
      toFilterString(productName).includes(textFilterString) ||
      toFilterString(productNumber).includes(textFilterString)
    ) {
      return true;
    }
    return false;
  };

  filteredItems(items: Product[], query: string, filterProps: FilterProps): Product[] {
    const filteredStockLocations = TagFilter.filterByTag(this.tagRelations, EntityType.stockLocation, filterProps);

    return items.filter(item => {
      if (
        filterProps.stockLocation &&
        filterProps.stockLocation.size &&
        !filterProps.stockLocation.has(item.stockLocationId)
      ) {
        return false;
      }

      if (filterProps.bin && filterProps.bin.size && !filterProps.bin.has(item.binId)) {
        return false;
      }

      if (filteredStockLocations.size && !filteredStockLocations.has(item.stockLocationId)) {
        return false;
      }

      return this.search(item, query);
    });
  }
}
