import { Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { t } from '../../../../types/translation/Translator';
import { CompanyContext } from '../../../../context/CompanyContext';
import { Company } from '../../../../types/company';
import Checkbox from '../../../../VentoryUI/components/common/Checkbox/Checkbox';
import { testIds } from '../../../../util/identifiers/identifiers.util';
import { FlexPane } from '../../../../VentoryUI/components/common/FlexPane/FlexPane';
import Paper from '../../../../VentoryUI/components/common/Paper/Paper';
import { IntegrationContext } from '../../../../context/IntegrationContext';
import { CompanyRelationService } from '../../../../types/integrationSettings';

interface CompanyFeatureTogglePaneInputProps {
  footer: (company: Company) => JSX.Element;
}

export default function CompanyFeatureTogglePane({ footer }: CompanyFeatureTogglePaneInputProps) {
  const { currentCompany } = useContext(CompanyContext);
  const { integrationSettings } = useContext(IntegrationContext);

  const [companyInput, setCompanyInput] = useState<Company>(new Company(currentCompany));

  return (
    <FlexPane
      wrapContent={false}
      testId={testIds.companyFeatureTogglePane}
      content={
        <Paper overflow='auto'>
          <Grid container rowSpacing={1}>
            {/* Alerts */}
            <Grid item xs={12}>
              <Checkbox
                dynamicUpdate
                label={t().alertsAlertFeatureToggle.singular.upper}
                value={companyInput.settings.featureToggles.alerts.alerts}
                onChange={v => setCompanyInput(companyInput.withAlertsAlertFeatureToggle(v))}
              />
            </Grid>
            {/* Orders */}
            <Grid item xs={12}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <Checkbox
                    dynamicUpdate
                    label={t().orderOrderFeatureToggle.singular.upper}
                    disabled={!companyInput.settings.featureToggles.productTransactions.productTransactions}
                    value={companyInput.settings.featureToggles.orders.orders}
                    onChange={v => setCompanyInput(companyInput.withOrdersOrderFeatureToggle(v))}
                  />
                </Grid>
                <Grid item xs={12} ml={4}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.orders.orders}
                    label={t().customer.plural.label}
                    value={companyInput.settings.featureToggles.orders.customer}
                    onChange={v => setCompanyInput(companyInput.withOrdersCustomer(v))}
                  />
                </Grid>
                <Grid item xs={12} ml={4}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.orders.orders}
                    label={t().supplier.plural.label}
                    value={companyInput.settings.featureToggles.orders.supplier}
                    onChange={v => setCompanyInput(companyInput.withOrdersSupplier(v))}
                  />
                </Grid>
                <Grid item xs={12} ml={4}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.orders.orders}
                    label={t().picking.plural.label}
                    value={companyInput.settings.featureToggles.orders.picking}
                    onChange={v => setCompanyInput(companyInput.withOrdersPicking(v))}
                  />
                </Grid>
                <Grid item xs={12} ml={4}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.orders.orders}
                    label={t().sendMailOnAssigned.singular.label}
                    value={companyInput.settings.featureToggles.orders.sendMailOnAssigned}
                    onChange={v => setCompanyInput(companyInput.withOrderSendMailOnAssigned(v))}
                  />
                </Grid>
                <Grid item xs={12} ml={4}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.orders.orders}
                    label={t().orderEditProductsOnInbound.singular.upper}
                    value={companyInput.settings.featureToggles.orders.editProductsOnInbound}
                    onChange={v => setCompanyInput(companyInput.withOrdersEditProductsOnInbound(v))}
                  />
                </Grid>
                <Grid item xs={12} ml={4}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.orders.orders}
                    label={t().orderEditProductsOnOutbound.singular.upper}
                    value={companyInput.settings.featureToggles.orders.editProductsOnOutbound}
                    onChange={v => setCompanyInput(companyInput.withOrdersEditProductsOnOutbound(v))}
                  />
                </Grid>
                <Grid item xs={12} ml={4}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.orders.orders}
                    label={t().orderProductSelectionFirst.singular.upper}
                    value={companyInput.settings.featureToggles.orders.productSelectionFirst}
                    onChange={v => setCompanyInput(companyInput.withOrdersProductSelectionFirst(v))}
                  />
                </Grid>
                <Grid item xs={12} ml={4}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.orders.orders}
                    label={t().ignoreAvailableQuantity.singular.upper}
                    value={companyInput.settings.featureToggles.orders.ignoreAvailableQuantity}
                    onChange={v => setCompanyInput(companyInput.withOrdersIgnoreAvailableQuantity(v))}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Product Transactions */}
            <Grid item xs={12}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <Checkbox
                    dynamicUpdate
                    label={t().productTransactionsProductTransactionFeatureToggle.singular.upper}
                    value={companyInput.settings.featureToggles.productTransactions.productTransactions}
                    onChange={v =>
                      setCompanyInput(companyInput.withProductTransactionsProductTransactionFeatureToggle(v))
                    }
                  />
                </Grid>
                <Grid item xs={12} ml={4}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.productTransactions.productTransactions}
                    label={t().productTransactionsWeb.singular.upper}
                    value={companyInput.settings.featureToggles.productTransactions.web}
                    onChange={v => setCompanyInput(companyInput.withWebProductTransactionFeatureToggle(v))}
                  />
                </Grid>
                <Grid item xs={12} ml={4}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.productTransactions.productTransactions}
                    label={t().productTransactionsMobile.singular.upper}
                    value={companyInput.settings.featureToggles.productTransactions.mobile}
                    onChange={v => setCompanyInput(companyInput.withMobileProductTransactionFeatureToggle(v))}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Quick Actions */}
            <Grid item xs={12}>
              <Checkbox
                dynamicUpdate
                label={t().productTransactionsQuickActions.singular.upper}
                value={companyInput.settings.featureToggles.productTransactions.quickActions}
                onChange={v => setCompanyInput(companyInput.withQuickActionsProductTransactionFeatureToggle(v))}
              />
            </Grid>
            {/* Tasks */}
            <Grid item xs={12}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <Checkbox
                    dynamicUpdate
                    label={t().tasksTaskFeatureToggle.singular.upper}
                    value={companyInput.settings.featureToggles.tasks.tasks}
                    onChange={v => setCompanyInput(companyInput.withTasksTaskFeatureToggle(v))}
                  />
                </Grid>
                <Grid item xs={12} ml={4}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.tasks.tasks}
                    label={t().cycleCount.singular.upper}
                    value={companyInput.settings.featureToggles.tasks.cycleCount}
                    onChange={v => setCompanyInput(companyInput.withTasksCycleCountToggle(v))}
                  />
                </Grid>
                <Grid item xs={12} ml={4}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.tasks.tasks}
                    label={t().blindCount.singular.upper}
                    value={companyInput.settings.featureToggles.tasks.blindCount}
                    onChange={v => setCompanyInput(companyInput.withTasksBlindCountToggle(v))}
                  />
                </Grid>
                <Grid item xs={12} ml={4}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.tasks.tasks}
                    label={t().tasksStockUpdateFeatureToggle.singular.upper}
                    value={companyInput.settings.featureToggles.tasks.allowStockUpdate}
                    onChange={v => setCompanyInput(companyInput.withTasksStockUpdateToggle(v))}
                  />
                </Grid>
                {[...integrationSettings.values()].find(item => item.type === CompanyRelationService.sap) ? (
                  <Grid item xs={12} ml={4}>
                    <Checkbox
                      dynamicUpdate
                      label={`${t().allowUncounted.singular.label} (SAP)`}
                      value={companyInput.settings.featureToggles.sap.allowUncounted}
                      onChange={v => setCompanyInput(companyInput.withSAPAllowUncountedFeatureToggle(v))}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12} ml={4}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.tasks.tasks}
                    label={t().orderOrderFeatureToggle.singular.upper}
                    value={companyInput.settings.featureToggles.tasks.taskOrders.taskOrders}
                    onChange={v => setCompanyInput(companyInput.withTaskOrderToggle(v))}
                  />
                </Grid>
                <Grid item xs={12} ml={8}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.tasks.taskOrders.taskOrders}
                    label={t().inbound.singular.upper}
                    value={companyInput.settings.featureToggles.tasks.taskOrders.inbound}
                    onChange={v => setCompanyInput(companyInput.withTaskOrderInboundToggle(v))}
                  />
                </Grid>
                <Grid item xs={12} ml={8}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.tasks.taskOrders.taskOrders}
                    label={t().move.singular.upper}
                    value={companyInput.settings.featureToggles.tasks.taskOrders.move}
                    onChange={v => setCompanyInput(companyInput.withTaskOrderMoveToggle(v))}
                  />
                </Grid>
                <Grid item xs={12} ml={8}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.tasks.taskOrders.taskOrders}
                    label={t().outbound.singular.upper}
                    value={companyInput.settings.featureToggles.tasks.taskOrders.outbound}
                    onChange={v => setCompanyInput(companyInput.withTaskOrderOutboundToggle(v))}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Products */}
            <Grid item xs={12}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <Checkbox
                    dynamicUpdate
                    label={t().productsProductToggle.singular.upper}
                    value={companyInput.settings.featureToggles.products.products}
                    onChange={v => setCompanyInput(companyInput.withProductsProductFeatureToggle(v))}
                  />
                </Grid>
                <Grid item xs={12} ml={4}>
                  <Checkbox
                    dynamicUpdate
                    disabled={!companyInput.settings.featureToggles.products.products}
                    label={t().productsGS1Toggle.singular.upper}
                    value={companyInput.settings.featureToggles.products.gs1Barcodes}
                    onChange={v => setCompanyInput(companyInput.withProductsGS1FeatureToggle(v))}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                  <Checkbox
                    dynamicUpdate
                    label={t().sessionStorageToggle.singular.upper}
                    value={companyInput.settings.featureToggles.company.sessionStorage}
                    onChange={v => setCompanyInput(companyInput.withCompanySessionStorageFeatureToggle(v))}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      }
      footer={footer(companyInput)}
    />
  );
}
